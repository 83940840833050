import { CommonModule } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ContactUsService } from './contact-us.service';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css'
})
export class HomeComponent {
  fb = inject(FormBuilder);
  contactService = inject(ContactUsService);

  contactForm!: FormGroup;
  sumbitMessage: string = '';
  submitting: boolean = false;


  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(50),
          Validators.pattern(/[A-Za-z\s'-]+/),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(100),
          Validators.email,
        ],
      ],
      phone: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(20),
          Validators.pattern(/^\+?[0-9()-\s]+$/),
        ],
      ],
      message: [
        '',
        [
          Validators.required,
          Validators.maxLength(500),
          Validators.pattern(/[A-Za-z\s'-]+/),
        ],
      ],
    });
  }

  errorsFor(controlName: string): string {
    const control = this.contactForm.get(controlName);
    if (control && control.dirty && control.errors) {
      switch (controlName) {
        case 'name':
          if (control.errors['required']) {
            return `*Name is required`;
          } else if (control.errors['maxlength']) {
            return `Maximum length exceeded for ${controlName}`;
          } else if (control.errors['pattern']) {
            return `Invalid ${controlName}`;
          }
          break;
        case 'email':
          if (control.errors['required']) {
            return `*Email is required`;
          } else if (control.errors['maxlength']) {
            return `Maximum length exceeded for ${controlName}`;
          } else if (control.errors['email']) {
            return `Invalid email format`;
          }
          break;
        case 'phone':
          if (
            control.errors['minlength'] ||
            control.errors['maxlength'] ||
            control.errors['pattern']
          ) {
            return `*Invalid phone number`;
          }
          break;
        case 'message':
          if (control.errors['required']) {
            return `*Message is required`;
          } else if (control.errors['maxlength']) {
            return `Maximum length exceeded for ${controlName}`;
          } else if (control.errors['pattern']) {
            return `Invalid ${controlName}`;
          }
          break;
      }
    }
    return '';
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.submitting = true;
      this.contactService.submitForm(this.contactForm.value).subscribe(
        (data) => {
          this.sumbitMessage = 'Form submitted successfully';
          this.contactForm.reset();
          this.submitting = false;
        },
        (error) => {
          this.sumbitMessage = 'Form submission failed';
          this.submitting = false;
        }
      );
    }
  }
}