<div class="bg-black">
    <!-- header.component.html -->
    <div class="max-w-7xl mx-auto">
        <img class="" src="../../../assets/logos/Artboard 1.svg" alt="image not displayed">
    </div>

    <!-- Pitch -->
    <div class="px-4 text-center">
        <h1 class="text-xl sm:text-3xl text-white">
            IT | Compliance | Consulting | Software | Cloud
        </h1>
    </div>

    <!-- Prompt for contact form -->
    <div class="flex items-center justify-center">
        <div class="text-center items-center max-w-[600px] p-4">
            <h2 class="text-1xl text-gray-400">
                While our website is under construction, please fill out this form or call us to find out how we can
                save your business up to 70% on IT costs.
            </h2>
        </div>
    </div>
    <!-- Contact form -->
    <div class="container mx-auto max-w-lg mb-[50px] p-4">
        <form class="flex flex-col relative" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
            <!-- Name -->
            <div class="flex flex-col">
                <label class="font-bold text-white">Name</label>
                <input formControlName="name" type="text" placeholder="Enter name..."
                    class="p-2 border border-gray-300 rounded">
                <span *ngIf="errorsFor('name')" class="text-red-500">{{ errorsFor('name') }}</span>
            </div>
            <!-- Email -->
            <div class="flex flex-col">
                <label class="font-bold text-white">Email</label>
                <input formControlName="email" type="email" placeholder="Enter email..."
                    class="p-2 border border-gray-300 rounded">
                <span *ngIf="errorsFor('email')" class="text-red-500">{{ errorsFor('email') }}</span>
            </div>
            <!-- Phone -->
            <div class="flex flex-col">
                <label class="font-bold text-white">Phone</label>
                <input formControlName="phone" type="tel" placeholder="Enter phone..."
                    class="p-2 border border-gray-300 rounded">
                <span *ngIf="errorsFor('phone')" class="text-red-500">{{ errorsFor('phone') }}</span>
            </div>
            <!-- Message -->
            <div class="flex flex-col">
                <label class="font-bold text-white">Message</label>
                <textarea formControlName="message" placeholder="Enter message..." rows="5"
                    class="p-2 border border-gray-300 rounded"></textarea>
                <span *ngIf="errorsFor('message')" class="text-red-500">{{ errorsFor('message') }}</span>
            </div>
            <div *ngIf="sumbitMessage" class="p-4 mt-2 border border-green-500 text-green-500 rounded-lg mx-auto">{{
                sumbitMessage }}
            </div>
            <!-- Submit Button -->
            <button [disabled]="sumbitMessage || contactForm.invalid" [class.disabled]="contactForm.invalid"
                class="cursor-pointer p-2 bg-black rounded text-white font-semibold mt-4 hover:bg-white hover:text-black disabled:text-gray-600 disabled:hover:bg-transparent"
                type="submit">SUBMIT</button>
            <!-- Spinner -->
            <div *ngIf="submitting" role="status"
                class="absolute inset-0 flex justify-center items-center bg-slate-500 bg-opacity-50 rounded-sm">
                <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor" />
                    <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill" />
                </svg>
            </div>
        </form>
        <!-- Contact Buttons -->
        <div class="flex flex-col sm:flex-row pt-4 space-y-2 items-center justify-between">
            <!-- Phone -->
            <div class="inline items-center">
                <img class="inline mr-2" src="../../../assets/icons/phone-solid.svg" alt="" width="20" height="20">
                <a href="tel:+18006061851" class="text-white hover:bg-white hover:text-black" target="_self"
                    aria-label="Go to tel:+18006061851">(800) 606-1851</a>
            </div>
            <!-- Email -->
            <div class="inline items-center">
                <img class="inline mr-2" src="../../../assets/icons/envelope-solid.svg" alt="" width="20" height="20">
                <a href="mailto:info@stonegoat-it.com"
                    class="text-white hover:bg-white hover:text-black">info&#64;stonegoat-it.com</a>
            </div>
        </div>
    </div>
</div>